const checkIfDateIsPast = (d) => { 
    const currentdate = new Date();
    const datetime = "Last Sync: " + currentdate.getDate() + "/" + (currentdate.getMonth() + 1) +
    "/" + currentdate.getFullYear() + " @ " +
    currentdate.getHours() + ":" +
    currentdate.getMinutes();
    const dateAfter = new Date(d);
    console.log(currentdate)
    return (currentdate > dateAfter)
}

const redirect = () => { 
    if (checkIfDateIsPast('2020-11-30T12:59:00')) location.href = "https://oticon-more.de";
}
if (checkIfDateIsPast('2020-11-30T12:00:00')) {
    setInterval(redirect, 10000)
}

